<template>
    <div>
        <myBreadcrumb :nav="nav"></myBreadcrumb>
        <div class="title">{{ info.name }}</div>
        <div class="detail">
            <span>{{ info.createTime }}</span>
            <span>访问量：{{ info.readCount }}</span>
        </div>
		<el-link icon="el-icon-view" :href="info.content" v-if="type==2">下载查看</el-link>
        <div style='white-space: pre-wrap;' class="vHtml" v-html="info.content ? info.content.replace(/<img/g, '<img style=width:100%;height:auto; ') : ''" v-else></div>
        <div class="butt">
            <!--            <span>上一篇</span>-->
            <!--            <span>上一篇</span>-->
        </div>
    </div>
</template>

<script>
import { getHeritageDetails, getPolicyDetails, getInheritorDetails, getHeritageActivityDetails } from '@/api';
export default {
    name: 'NewsDetail',
    data() {
        return {
            info: {},
            nav: [
                {
                    title: '首页',
                    to: { path: '/' },
                },
                {
                    title: '非遗文化',
                    to: { path: '/nonHeritage/inherit' },
                },
                {
                    title: '文章详情',
                },
            ],
			type: -1
        };
    },
    created() {
        let type = this.$route.query.type;
		this.type = type;
        if (type == 1) {
            this.getHeritageList();
        } else if (type == 2) {
            this.getPolicyDetails();
        } else if (type == 3) {
            this.getInheritorDetails();
        } else {
            this.getHeritageActivityDetails();
        }
    },
    methods: {
        getHeritageList() {
            console.log(111);
            getHeritageDetails(this.$route.query.id).then((res) => {
                console.log(res);
                this.info = res.data;
            });
        },
        getPolicyDetails() {
            getPolicyDetails(this.$route.query.id).then((res) => {
                console.log(res);
                this.info = res.data;
            });
        },
        getInheritorDetails() {
            getInheritorDetails(this.$route.query.id).then((res) => {
                console.log(res);
                this.info = res.data;
            });
        },
        getHeritageActivityDetails() {
            getHeritageActivityDetails(this.$route.query.id).then((res) => {
                console.log(res);
                this.info = res.data;
            });
        },
    },
};
</script>

<style scoped lang="less">
.title {
    font-weight: 700;
    font-size: 32px;
    line-height: 46px;
    color: rgba(0, 0, 0, 0.8);
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}
.detail {
    display: flex;
    justify-content: space-between;
    font-size: 17px;
    line-height: 20px;
    margin: 20px 0 40px 0;
    color: rgba(0, 0, 0, 0.5);
}
.butt {
    display: flex;
    justify-content: space-between;
    font-size: 17px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.5);
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    padding-top: 20px;
    margin: 70px 0 100px 0;
}
</style>
